// Hungarian notation
// (http://en.wikipedia.org/wiki/Hungarian_notation)
// n - HTML-Node
// o - object
// s - string
// i - integer
// a - array
// b - boolean
// f - float
// p - Particle
// fn - function
// ctx - 2D Context

// General Functions
const fnRequestAnimationFrame = function (fnCallback) {
  const fnAnimFrame =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (fnCallback) {
      window.setTimeOut(fnCallback, 1000 / 60)
    }
  fnAnimFrame(fnCallback)
}

// Add Event Listener
const fnAddEventListener = function (o, sEvent, fn) {
  if (o.addEventListener) {
    o.addEventListener(sEvent, fn, false)
  } else {
    o[`on${sEvent}`] = fn
  }
}

const app = function () {
  let h

  // General Elements
  const oDoc = document
  const nBody = oDoc.body
  // Shortcuts
  const fPI = Math.PI
  const fnMax = Math.max
  const fnMin = Math.min
  const fnRnd = Math.random
  const fnRnd2 = () => 2.0 * fnRnd() - 1.0
  const fnCos = Math.cos
  const fnACos = Math.acos
  const fnSin = Math.sin
  // Sphere Settings
  const iRadiusSphere = 150
  let iProjSphereX = 0
  let iProjSphereY = 0
  // Particle Settings
  const fMaxAX = 0.1
  const fMaxAY = 0.1
  const fMaxAZ = 0.1
  const fStartVX = 0.001
  const fStartVY = 0.001
  const fStartVZ = 0.001
  let fAngle = 0.0
  let fSinAngle = 0.0
  let fCosAngle = 0.0

  window.iFramesToRotate = 2500.0
  window.iPerspective = 180
  window.iNewParticlePerFrame = 5
  window.fGrowDuration = 25.0
  window.fWaitDuration = 50.0
  window.fShrinkDuration = 100.0
  window.aColor = [11, 72, 178]

  let fVX = (2.0 * fPI) / window.iFramesToRotate

  const oRadGrad = null
  const nCanvasRender = document.getElementById('nCanvasRender')
  const ctxRender = nCanvasRender.getContext('2d')

  const oRender = { pFirst: null }
  const oBuffer = { pFirst: null }

  let w = (h = 0)

  // gets/sets size
  const fnSetSize = function () {
    nCanvasRender.width = w = window.innerWidth
    nCanvasRender.height = h = window.innerHeight
    iProjSphereX = w / 2
    iProjSphereY = h / 2
    return { w, h }
  }

  fnSetSize()

  // window.onresize
  fnAddEventListener(window, 'resize', fnSetSize)

  const fnSwapList = function (p, oSrc, oDst) {
    if (p != null) {
      // remove p from oSrc
      if (oSrc.pFirst === p) {
        oSrc.pFirst = p.pNext
        if (p.pNext != null) {
          p.pNext.pPrev = null
        }
      } else {
        p.pPrev.pNext = p.pNext
        if (p.pNext != null) {
          p.pNext.pPrev = p.pPrev
        }
      }
    } else {
      // create new p
      p = new Particle()
    }

    p.pNext = oDst.pFirst
    if (oDst.pFirst != null) {
      oDst.pFirst.pPrev = p
    }
    oDst.pFirst = p
    p.pPrev = null
    return p
  }

  // Particle
  class Particle {
    static initClass () {
      // Current Position
      this.prototype.fX = 0.0
      this.prototype.fY = 0.0
      this.prototype.fZ = 0.0
      // Current Velocity
      this.prototype.fVX = 0.0
      this.prototype.fVY = 0.0
      this.prototype.fVZ = 0.0
      // Current Acceleration
      this.prototype.fAX = 0.0
      this.prototype.fAY = 0.0
      this.prototype.fAZ = 0.0
      // Projection Position
      this.prototype.fProjX = 0.0
      this.prototype.fProjY = 0.0
      // Rotation
      this.prototype.fRotX = 0.0
      this.prototype.fRotZ = 0.0
      // double linked list
      this.prototype.pPrev = null
      this.prototype.pNext = null

      this.prototype.fAngle = 0.0
      this.prototype.fForce = 0.0

      this.prototype.fGrowDuration = 0.0
      this.prototype.fWaitDuration = 0.0
      this.prototype.fShrinkDuration = 0.0

      this.prototype.fRadiusCurrent = 0.0

      this.prototype.iFramesAlive = 0
      this.prototype.bIsDead = false
    }

    fnInit () {
      this.fAngle = fnRnd() * fPI * 2
      this.fForce = fnACos(fnRnd2())
      this.fAlpha = 0
      this.bIsDead = false
      this.iFramesAlive = 0
      this.fX = iRadiusSphere * fnSin(this.fForce) * fnCos(this.fAngle)
      this.fY = iRadiusSphere * fnSin(this.fForce) * fnSin(this.fAngle)
      this.fZ = iRadiusSphere * fnCos(this.fForce)
      this.fVX = fStartVX * this.fX
      this.fVY = fStartVY * this.fY
      this.fVZ = fStartVZ * this.fZ
      this.fGrowDuration =
        window.fGrowDuration + fnRnd2() * (window.fGrowDuration / 4.0)
      this.fWaitDuration =
        window.fWaitDuration + fnRnd2() * (window.fWaitDuration / 4.0)
      this.fShrinkDuration =
        window.fShrinkDuration + fnRnd2() * (window.fShrinkDuration / 4.0)
      this.fAX = 0.0
      this.fAY = 0.0
      this.fAZ = 0.0
    }

    fnUpdate () {
      if (this.iFramesAlive > this.fGrowDuration + this.fWaitDuration) {
        this.fVX += this.fAX + fMaxAX * fnRnd2()
        this.fVY += this.fAY + fMaxAY * fnRnd2()
        this.fVZ += this.fAZ + fMaxAZ * fnRnd2()
        this.fX += this.fVX
        this.fY += this.fVY
        this.fZ += this.fVZ
      }

      this.fRotX = fCosAngle * this.fX + fSinAngle * this.fZ
      this.fRotZ = -fSinAngle * this.fX + fCosAngle * this.fZ
      this.fRadiusCurrent = Math.max(
        0.01,
        window.iPerspective / (window.iPerspective - this.fRotZ)
      )
      this.fProjX = this.fRotX * this.fRadiusCurrent + iProjSphereX
      this.fProjY = this.fY * this.fRadiusCurrent + iProjSphereY

      this.iFramesAlive += 1

      if (this.iFramesAlive < this.fGrowDuration) {
        this.fAlpha = (this.iFramesAlive * 1.0) / this.fGrowDuration
      } else if (this.iFramesAlive < this.fGrowDuration + this.fWaitDuration) {
        this.fAlpha = 1.0
      } else if (
        this.iFramesAlive <
        this.fGrowDuration + this.fWaitDuration + this.fShrinkDuration
      ) {
        this.fAlpha =
          ((this.fGrowDuration +
            this.fWaitDuration +
            this.fShrinkDuration -
            this.iFramesAlive) *
            1.0) /
          this.fShrinkDuration
      } else {
        this.bIsDead = true
      }

      if (this.bIsDead === true) {
        fnSwapList(this, oRender, oBuffer)
      }

      this.fAlpha *= fnMin(1.0, fnMax(0.5, this.fRotZ / iRadiusSphere))
      this.fAlpha = fnMin(1.0, fnMax(0.0, this.fAlpha))
    }
  }
  Particle.initClass()

  const fnRender = function () {
    ctxRender.fillStyle = '#00171f'
    ctxRender.fillRect(0, 0, w, h)

    let p = oRender.pFirst
    let iCount = 0
    while (p != null) {
      ctxRender.fillStyle = `rgba(${window.aColor.join(',')},${p.fAlpha.toFixed(
        4
      )})`
      ctxRender.beginPath()
      ctxRender.arc(p.fProjX, p.fProjY, p.fRadiusCurrent, 0, 2 * fPI, false)
      ctxRender.closePath()
      ctxRender.fill()
      p = p.pNext
      iCount += 1
    }
  }

  const fnNextFrame = function () {
    let p
    fAngle = (fAngle + fVX) % (2.0 * fPI)
    fSinAngle = fnSin(fAngle)
    fCosAngle = fnCos(fAngle)

    let iAddParticle = 0
    let iCount = 0
    while (iAddParticle++ < window.iNewParticlePerFrame) {
      p = fnSwapList(oBuffer.pFirst, oBuffer, oRender)
      p.fnInit()
    }

    p = oRender.pFirst
    while (p != null) {
      const { pNext } = p
      p.fnUpdate()
      p = pNext
      iCount++
    }
    fnRender()

    return fnRequestAnimationFrame(() => fnNextFrame())
  }

  fnNextFrame()

  window.app = this
}

fnAddEventListener(window, 'load', app)
